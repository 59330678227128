import { BaseModel, Product, Fund, Holding } from '@/models';
import ProposalInvestmentStatusEnum from '@/enums/proposal/investmentStatus';
import ProductApi from '@/api/ProductApi';
import FundApi from '@/api/FundApi';
import $m from '@/lib/money';
import floor from '@/lib/helpers/floor';
import toPercentage from '@/lib/helpers/toPercentage';

export class ProposalInvestment extends BaseModel {
    static entity = 'proposal-investments';

    static fields() {
        return {
            ...super.fields(),
            status: this.enum(ProposalInvestmentStatusEnum, null).nullable(),
            amount: this.attr(this.defaultMoney).nullable(),
            exact_amount: this.number().nullable(),
            minimum_amount: this.number(0).nullable(),
            share_quantity: this.number(null).nullable(),
            share_price: this.attr(this.defaultMoney).nullable(),
            distribution_percentage: this.number(null).nullable(),
            default_investment_percentage: this.number(null).nullable(),
            closed: this.boolean(false).nullable(),
            precision: this.number(0).nullable(),
            use_percentage: this.boolean(false).nullable(),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id'),

            holding_id: this.string(null).nullable(),
            holding: this.belongsTo(Holding, 'holding_id')
        };
    }

    static mock(data = {}) {
        return {
            status: faker =>
                faker.helpers.arrayElement([
                    ProposalInvestmentStatusEnum.REJECTED,
                    ProposalInvestmentStatusEnum.PENDING_ALLOTMENT,
                    ProposalInvestmentStatusEnum.COMPLETED
                ]),
            amount: faker => ({
                amount: faker.number.float(10000, 1000000),
                currency: 'GBP'
            }),
            share_quantity: faker => faker.number.int(1, 10000),
            share_price: faker => ({
                amount: faker.number.float(1, 100),
                currency: 'GBP'
            }),
            product: ProductApi,
            product_id: (faker, item) => item.product.id,
            fund: FundApi,
            fund_id: (faker, item) => item.fund.id,
            holding: null,
            holding_id: null,
            ...data
        };
    }

    get is_closed() {
        return this.closed;
    }

    get is_open() {
        return !this.closed;
    }

    get name() {
        if (this.product) {
            return this.product.name;
        } else if (this.fund) {
            return this.fund.name;
        }
        return null;
    }

    get currency() {
        if (this.product) {
            return this.product.currency || 'GBP';
        } else if (this.fund) {
            return this.fund.currency || 'GBP';
        }
        return 'GBP';
    }

    get is_rejected() {
        return this.status === ProposalInvestmentStatusEnum.REJECTED;
    }

    get is_pending_allotment() {
        return this.status === ProposalInvestmentStatusEnum.PENDING_ALLOTMENT;
    }

    get is_completed() {
        return this.status === ProposalInvestmentStatusEnum.COMPLETED;
    }

    get is_custom_distribution() {
        return (
            this.distribution_percentage !== null && this.distribution_percentage !== this.default_investment_percentage
        );
    }

    get distribution_percentage_display() {
        return toPercentage(this.distribution_percentage, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    get status_type() {
        if (this.is_rejected) {
            return 'error';
        } else if (this.is_pending_allotment) {
            return 'warning';
        } else if (this.is_completed) {
            return 'success';
        }
        return null;
    }

    get has_rounded_amount() {
        const fixed = this.amount?.amount || 0;
        const exact = this.exact_amount || 0;

        return fixed !== exact && fixed === Number(exact.toFixed(2));
    }

    get rounded_loss() {
        const fixed = this.amount?.amount || 0;
        const exact = this.exact_amount || 0;

        return fixed - exact;
    }

    recalculateDistributionPercentage(total = 0) {
        this.distribution_percentage = this.getCalculatedDistributionPercentage(total);
    }

    getCalculatedDistributionPercentage(total = 0) {
        total = this.fromMoney(total);

        if (this.is_closed || !total || !this.amount?.amount) {
            return 0;
        }

        return $m(this.amount?.amount, { precision: 4 }).divide(total).value;
    }

    getMinimumDistributionPercentage(total = 0) {
        total = this.fromMoney(total);

        if (!total || !this.minimum_amount) {
            return 0;
        }

        return $m(this.minimum_amount).divide(total).value;
    }

    recalculateAmountFromPercentage(total = 0) {
        this.amount = this.getCalculatedAmountFromPercentage(total);
    }

    getCalculatedAmountFromPercentage(total = 0) {
        total = this.fromMoney(total);

        if (this.is_closed || !total || !this.distribution_percentage) {
            return this.toMoney(0);
        }

        const amount = $m(total).multiply(this.distribution_percentage, { precision: this.precision + 2 }).value;

        return this.toMoney(amount);
    }

    recalculateShareQuantity() {
        if (this.share_price) {
            const fixedAmount = this.amount?.amount || null;
            const exactAmount = this.exact_amount || null;

            if (exactAmount && fixedAmount !== exactAmount && fixedAmount === Number(exactAmount.toFixed(2))) {
                // If amount is not equal to the exact amount and the amount is equal to the exact amount rounded
                // to 2 decimal places, do not recalculate share quantity.
                return;
            }

            const data = this.getCalculatedShareQuantity(this.amount);

            if (data) {
                this.share_quantity = data.share_quantity;
                this.amount = data.amount;
                this.exact_amount = data.amount?.amount;

                return;
            }
        }

        this.share_quantity = 0;
    }

    getCalculatedShareQuantity(amount) {
        if (this.is_closed) {
            return null;
        }

        if (!amount || !amount?.amount) {
            return null;
        }

        if (!this.share_price || !this.share_price?.amount) {
            return null;
        }

        let share_quantity = floor($m(Math.abs(amount.amount)).divide(this.share_price.amount).value, 0);
        amount = $m(share_quantity).multiply(this.share_price.amount, { precision: this.precision }).value;

        return { share_quantity, amount: { amount, currency: this.currency } };
    }

    recalculateAmountFromShareQuantity() {
        if (this.share_quantity) {
            const data = this.getCalculatedAmountFromShareQuantity(this.share_quantity);

            this.amount = data.amount;
            this.share_quantity = data.share_quantity;

            if (this.amount && this.amount.amount) {
                const exactAmount = this.amount.amount;

                this.exact_amount = exactAmount;

                const fixedAmount = floor(exactAmount, this.precision);

                if (exactAmount !== fixedAmount) {
                    this.amount.amount = fixedAmount;
                }
            } else {
                this.exact_amount = 0;
            }
        }
    }

    getCalculatedAmountFromShareQuantity(share_quantity) {
        const data = {
            share_quantity: 0,
            amount: this.toMoney(0)
        };

        if (this.is_closed || !share_quantity) {
            return data;
        }

        share_quantity = Number(share_quantity);

        if (isNaN(share_quantity)) {
            return data;
        }

        data.share_quantity = share_quantity;

        if (!this.share_price || !this.share_price?.amount) {
            return data;
        }

        data.amount.amount = $m(share_quantity).multiply(this.share_price.amount, {
            precision: this.precision
        }).value;

        return data;
    }

    getMaximumDistributionPercentage(total_percentage = 0) {
        return $m(this.distribution_percentage).add($m(1).subtract(total_percentage).value).value;
    }
}

export default ProposalInvestment;
