import { MediaCollection, MediaInvestor } from '@/models';
import i18n from '@/lib/i18n';
import store from '@/store';
import MediaFormatEnum from '@/enums/media/format';
import MediaInvestorTypeEnum from '@/enums/media/investor/type';
import QuarantineStatusEnum from '@/enums/quarantineStatus';

export class MediaInvestorCollection extends MediaCollection {
    static type = 'MediaInvestorCollection';
    static endpoint = MediaInvestor.api.index;
    static model = MediaInvestor;

    static fields() {
        return {
            ...super.fields(),
            investor_id: this.string(null).nullable()
        };
    }

    get show_quarantined_media() {
        return store.getters['preferences/show_quarantined_media'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                filterable: true,
                width: '14rem'
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                sortable: true,
                width: '20rem'
            }),
            this.createHeader('description', i18n.t('description'), {
                width: '18rem',
                format: 'wysiwyg'
            }),
            this.createHeader('document_date', i18n.t('document_date'), {
                filterable: true,
                width: '10rem',
                format: 'datetime'
            }),
            this.createHeader('format', i18n.t('format'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: MediaFormatEnum,
                enumA11yKey: 'enums.media_format'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '14rem',
                format: 'enum',
                enum: MediaInvestorTypeEnum,
                enumA11yKey: 'enums.media_investor_type'
            }),
            this.createHeader('investor_name', i18n.t('investor'), {
                path: 'investor.name',
                width: '18rem'
            }),
            this.createHeader('adviser_name', i18n.t('adviser'), {
                path: 'adviser.name',
                width: '18rem'
            }),
            this.createHeader('holding_name', i18n.t('holding'), {
                path: 'holding.name',
                width: '18rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            // filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
            filters.investor_id = this.investor_id;
        }

        if (this.media_format) {
            filters.format = this.media_format_filter_value;
        }

        if (this.media_type) {
            filters.type = this.media_type_filter_value;
        }

        if (this.show_quarantined_media) {
            filters.quarantine_status = this.filterOperator('is_not') + ':' + QuarantineStatusEnum.PUBLISHED;
        } else {
            filters.quarantine_status = this.filterOperator('is') + ':' + QuarantineStatusEnum.PUBLISHED;
        }

        return filters;
    }
}

export default MediaInvestorCollection;
