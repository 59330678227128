import { BaseModel, Offer, FeeElement } from '@/models';
import FeePlanApi from '@/api/FeePlanApi';
import FeeTaxTypeEnum from '@/enums/fee/taxType';
import FeeTypeEnum from '@/enums/fee/type';
import FeeGroupEnum from '@/enums/fee/group';

export class FeePlan extends BaseModel {
    static name = 'FeePlan';
    static entity = 'feePlans';
    static Api = FeePlanApi;
    static runSetup = true;

    async setupLogic() {
        if (this.tax_type && Array.isArray(this.entries)) {
            for (const entry of this.entries) {
                entry.tax_type = this.tax_type;
            }
        }
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(null).nullable(),
            tax_type: this.enum(FeeTaxTypeEnum).nullable(),
            entries: this.modelList(FeeElement, []).nullable(),
            parent: this.attr(null).nullable(),

            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id'),

            // The following legacy fields will be removed in the future

            initial: this.number(null).nullable(),
            initial_description: this.string(null).nullable(),

            existing_investor_initial: this.number(null).nullable(),
            existing_investor_initial_description: this.string(null).nullable(),

            annual_management: this.number(null).nullable(),
            annual_management_description: this.string(null).nullable(),

            performance: this.number(null).nullable(),
            performance_description: this.string(null).nullable(),

            performance_hurdle: this.string(null).nullable(),

            additional: this.number(null).nullable(),
            additional_description: this.string(null).nullable(),

            administration: this.attr(this.defaultMoney).nullable(),
            administration_description: this.string(null).nullable(),

            investee_initial: this.number(null).nullable(),
            investee_initial_description: this.string(null).nullable(),

            investee_ongoing: this.number(null).nullable(),
            investee_ongoing_description: this.string(null).nullable(),

            investee_additional: this.number(null).nullable(),
            investee_additional_description: this.string(null).nullable()
        };
    }

    get parent_id() {
        return this.parent?.id || null;
    }

    get parent_name() {
        return this.parent?.name || null;
    }

    set type(taxType) {
        this.tax_type = taxType;

        if (this.tax_type in this.entries_template_map) {
            this.entries = this.entries_template_map[this.tax_type].map(
                fe =>
                    new FeeElement({
                        tax_type: this.tax_type,
                        ...fe
                    })
            );
        } else {
            this.entries = [];
            throw new Error(`Unsupported tax type: ${taxType}`);
        }
    }
    get type() {
        return this.tax_type;
    }

    get has_entries() {
        if (!this.entries) {
            return false;
        }

        if (this.entries.length === 0) {
            return false;
        }

        return this.entries.some(fe => fe.$dirty);
    }

    get entries_template_map() {
        return {
            [FeeTaxTypeEnum.EIS]: FeeElementsTemplate.SEISEIS,
            [FeeTaxTypeEnum.SEIS]: FeeElementsTemplate.SEISEIS,
            [FeeTaxTypeEnum.IHT]: FeeElementsTemplate.IHT,
            [FeeTaxTypeEnum.VCT]: FeeElementsTemplate.VCT
        };
    }

    get group_keys() {
        return [...new Set(this.entries.map(element => element.group).filter(group => group !== null))];
    }

    get groups() {
        const groups = this.group_keys.reduce((acc, key) => {
            acc[`${key}`] = [];
            return acc;
        }, {});

        this.entries.forEach(element => {
            if (element.group && element.group in groups) {
                groups[element.group].push(element);
            }
        });

        return groups;
    }
}

export const FeeElementsTemplate = {
    SEISEIS: [
        {
            name: 'initial_fee',
            label: 'Initial Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.INITIAL
        },
        {
            name: 'annual_management_charge',
            label: 'Annual Management Charge',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.ANNUAL_MANAGEMENT
        },
        {
            name: 'custody_fee',
            label: 'Custody Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.CUSTODY
        },
        {
            name: 'dealing_fee',
            label: 'Dealing Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.DEALING
        },
        {
            name: 'other_initial_fee',
            label: 'Other Initial Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.OTHER_INITIAL
        },
        {
            name: 'other_ongoing_fee',
            label: 'Other Ongoing Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.OTHER_ONGOING
        },
        {
            name: 'performance_fee_1',
            label: 'Performance Fee 1',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.PERFORMANCE
        },
        {
            name: 'performance_fee_2',
            label: 'Performance Fee 2',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.PERFORMANCE
        },
        {
            name: 'exit_fee',
            label: 'Exit Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.EXIT
        },
        {
            name: 'investor_funds_for_tax_relief',
            label: 'Investor Funds deployed for Tax Relief',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.DEPLOYMENT_FACTOR
        },
        {
            name: 'investee_initial_fee',
            label: 'Initial Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_INITIAL
        },
        {
            name: 'investee_annual_management_charge',
            label: 'Annual Management Charge',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT
        },
        {
            name: 'investee_administration_fee',
            label: 'Administration Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_ADMINISTRATION
        },
        {
            name: 'investee_dealing_fee',
            label: 'Dealing Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_DEALING
        },
        {
            name: 'investee_mentoring_fee',
            label: 'Mentoring Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_MENTORING
        },
        {
            name: 'investee_other_fee',
            label: 'Other Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_OTHER
        },
        {
            name: 'investee_board_fee',
            label: 'Board or Monitoring Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_BOARD
        }
    ],
    VCT: [
        {
            name: 'annual_management_charge',
            label: 'Annual Management Charge',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.ANNUAL_MANAGEMENT
        },
        {
            name: 'other_fee_ongoing',
            label: 'Other Fee Ongoing',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.OTHER_ONGOING
        },
        {
            name: 'performance_fee_1',
            label: 'Performance Fee 1',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.PERFORMANCE
        },
        {
            name: 'aic_ongoing_charge',
            label: 'AIC Ongoing Charge',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.AIC_ONGOING
        },
        {
            name: 'annual_expense_cap',
            label: 'Annual Expenses Cap',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.EXPENSE_CAP
        }
    ],
    IHT: [
        {
            name: 'initial_fee',
            label: 'Initial Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.INITIAL
        },
        {
            name: 'annual_management_charge',
            label: 'Annual Management Charge',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.ANNUAL_MANAGEMENT
        },
        {
            name: 'custody_fee',
            label: 'Custody Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.CUSTODY
        },
        {
            name: 'dealing_fee',
            label: 'Dealing Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.DEALING
        },
        {
            name: 'other_initial_fee',
            label: 'Other Initial Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.OTHER_INITIAL
        },
        {
            name: 'other_ongoing_fee',
            label: 'Other Ongoing Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.OTHER_ONGOING
        },
        {
            name: 'performance_fee_1',
            label: 'Performance Fee 1',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.PERFORMANCE
        },
        {
            name: 'exit_fee',
            label: 'Exit Fee',
            group: FeeGroupEnum.INVESTOR,
            type: FeeTypeEnum.EXIT
        },
        {
            name: 'investee_initial_fee',
            label: 'Initial Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_INITIAL
        },
        {
            name: 'investee_annual_management_charge',
            label: 'Annual Management Charge',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_ANNUAL_MANAGEMENT
        },
        {
            name: 'investee_administration_fee',
            label: 'Administration Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_ADMINISTRATION
        },
        {
            name: 'investee_dealing_fee',
            label: 'Dealing Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_DEALING
        },
        {
            name: 'investee_mentoring_fee',
            label: 'Mentoring Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_MENTORING
        },
        {
            name: 'investee_other_fee',
            label: 'Other Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_OTHER
        },
        {
            name: 'investee_board_fee',
            label: 'Board or Monitoring Fee',
            group: FeeGroupEnum.INVESTEE_COMPANY,
            type: FeeTypeEnum.INVESTEE_BOARD
        }
    ]
};

export default FeePlan;
