import ProposalCalculator from '@/calc/ProposalCalculator';
import ProposalSolveAmountForEnum from '@/enums/proposal/solveAmountFor';
import FeeCollectionEnum from '@/enums/feeCollection';

export class ProposalFeesAddedCalculator extends ProposalCalculator {
    constructor(properties = {}) {
        super();
        for (const key in properties) {
            if (properties[`${key}`] !== null && properties[`${key}`] !== undefined) {
                this[`${key}`] = properties[`${key}`];
            }
        }
    }

    _solve_amount_for = ProposalSolveAmountForEnum.APPLICATION_AMOUNT;

    get interim_amount() {
        return this.amount;
    }

    recalculateTotals() {
        this.recalculateApplicationAmount();
        this.recalculateClearedFunds();
    }

    recalculateAmount() {
        this._amount = this.$m(this.cleared_funds, { precision: 8 })
            .subtract(this.initial_adviser_fee_by_collection)
            .subtract(this.annual_adviser_fee_by_collection)
            .subtract(this.annual_platform_fee_by_collection).value;

        this._initial_amount = this._amount;

        this.log('Recalculating amount', {
            calculation: `${this.$m(this.cleared_funds, { precision: 8 }).value} - ${
                this.initial_adviser_fee_by_collection.value
            } - ${this.annual_adviser_fee_by_collection.value} - ${this.annual_platform_fee_by_collection}`,
            output: this._amount
        });
    }

    recalculateApplicationAmount() {
        let amount = this.$m(this.amount, { precision: 8 });

        if (this.initial_adviser_fee_collection === FeeCollectionEnum.FACILITATED_PROVIDER) {
            amount = amount.add(this.initial_adviser_fee);
        }

        this._application_amount = amount.value;

        this.log('Recalculating application amount', {
            calculation: `${this.$m(this.amount, { precision: 8 }).value} + ${
                this.initial_adviser_fee_collection === FeeCollectionEnum.FACILITATED_PROVIDER
                    ? this.initial_adviser_fee
                    : 0
            }`,
            output: this._application_amount
        });
    }

    recalculateClearedFunds() {
        this._cleared_funds = this.$m(this.amount, { precision: 8 })
            .add(this.initial_adviser_fee_by_collection)
            .add(this.annual_adviser_fee_by_collection)
            .add(this.annual_platform_fee_by_collection).value;

        this.log('Recalculating cleared_funds', {
            calculation: `${this.$m(this.amount, { precision: 8 }).value} + ${
                this.initial_adviser_fee_by_collection
            } + ${this.annual_adviser_fee_by_collection} + ${this.annual_platform_fee_by_collection}`,
            output: this._cleared_funds
        });
    }

    //

    /**
     * Initial Amount
     * @param {number} value
     */
    set initial_amount(value) {
        super.initial_amount = value;
        this.amount = value;
    }
    get initial_amount() {
        return super.initial_amount;
    }

    /**
     * Investment Amount
     * @param {number} value
     */
    set amount(value) {
        super.amount = value;
        this.recalculateFees();
        this.recalculateTotals();
    }
    get amount() {
        return super.amount;
    }

    /**
     * Application Amount
     * @param {number} value
     */
    set application_amount(value) {
        throw new Error('Cannot set application_amount.');
    }
    get application_amount() {
        return super.application_amount;
    }

    /**
     * Cleared Funds
     * @param {number} value
     */
    set cleared_funds(value) {
        throw new Error('Cannot set cleared funds when calculating with "Fees Added". Use "Fees Deducted" instead.');
    }
    get cleared_funds() {
        return super.cleared_funds;
    }
}

export default ProposalFeesAddedCalculator;
