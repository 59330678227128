import { BaseModel, User } from '@/models';
import TermsApi from '@/api/TermsApi';
import TermsStatusEnum from '@/enums/terms/status';
import TermsTypeEnum from '@/enums/terms/type';
import i18n from '@/lib/i18n';
import getFilterOperator from '@/lib/helpers/getFilterOperator';

export class Terms extends BaseModel {
    static name = 'Terms';
    static entity = 'terms';
    static Api = TermsApi;
    static runSetup = true;

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            status: this.enum(TermsStatusEnum, TermsStatusEnum.DRAFT).nullable(),
            type: this.enum(TermsTypeEnum, null).nullable(),
            summary: this.attr(null).nullable(),
            document: this.attr(null).nullable(),
            effective_at: this.string(null).nullable(),
            created_at: this.string(null).nullable(),
            created_by_id: this.string(null).nullable(),
            created_by: this.belongsTo(User, 'created_by_id'),
            last_updated_at: this.string(null).nullable(),
            last_updated_by_id: this.string(null).nullable(),
            last_updated_by: this.belongsTo(User, 'last_updated_by_id'),
            //
            accepted: this.boolean(false),
            accepted_at: this.string(null).nullable()
        };
    }

    setupLogic() {
        if (this.id && this.type === null) {
            this.type = TermsTypeEnum.GLOBAL;
        }
    }

    $toPayload() {
        const pl = super.$toPayload();

        if (pl.type === TermsTypeEnum.GLOBAL) {
            pl.type = null;
        }

        return pl;
    }

    get name() {
        if (!this.type) {
            return i18n.t('global');
        }

        return i18n.t(`enums.terms_type.${this.type}`);
    }

    get is_global() {
        return this.type === TermsTypeEnum.GLOBAL;
    }

    get is_draft() {
        return this.status === TermsStatusEnum.DRAFT;
    }

    get is_approved() {
        return this.status === TermsStatusEnum.APPROVED;
    }
    get is_live() {
        return this.is_approved;
    }

    get is_superceded() {
        return this.status === TermsStatusEnum.SUPERCEDED;
    }

    get can_be_viewed() {
        return true;
    }

    get can_be_edited() {
        return this.is_draft;
    }

    get can_be_copied() {
        return this.effective_at && this.document;
    }

    get can_be_published() {
        return this.is_draft && this.effective_at && this.document;
    }

    get can_be_deleted() {
        return this.is_draft;
    }

    hasType(type) {
        if (type === 'GLOBAL') {
            type === null;
        }

        return this.type === type || (type === null && this.type === 'GLOBAL');
    }

    async $getCurrentTermsConditions() {
        const found = await this.constructor.search({
            status: `${getFilterOperator('is')}:${TermsStatusEnum.APPROVED}`,
            type: this.is_global ? getFilterOperator('empty') : `${getFilterOperator('is')}:${this.type}`
        });

        if (!found || !found.length) {
            return null;
        }

        return found.map(t => new Terms(t)).find(t => t);
    }

    async $remove() {
        this.$removing = true;

        return await this.constructor.$remove(this.id).finally(() => {
            this.$removing = false;
        });
    }

    async $copy({ dry = false }) {
        const copy = new Terms(this.$toPayload());

        copy.id = null;
        copy.status = TermsStatusEnum.DRAFT;
        copy.created_at = null;
        copy.created_by_id = null;
        copy.created_by = null;
        copy.last_updated_at = null;
        copy.last_updated_by_id = null;
        copy.last_updated_by = null;

        if (dry) {
            return copy;
        }

        return await copy.$create();
    }
}

export default Terms;
