const config = {
    ENV: process.env.NODE_ENV,
    /**
     * API
     */
    API_BASE_URL: process.env.VUE_APP_API_BASE_URL || 'https://api.growthinvest.localhost',
    API_TIMEOUT: parseInt(process.env.VUE_APP_API_TIMEOUT) || 30000,
    API_LIFETIME: parseInt(process.env.API_LIFETIME) || 5000,
    /**
     * Application
     */
    APP_ENV: process.env.VUE_APP_ENV,
    APP_BASE_URL: process.env.VUE_APP_BASE_URL || 'https://app.growthinvest.localhost',
    APP_MAX_ONBOARDING_INVESTOR_TYPE_ATTEMPTS: parseInt(process.env.VUE_APP_MAX_ONBOARDING_INVESTOR_TYPE_ATTEMPTS) || 3,
    APP_FILTER_STORAGE: process.env.VUE_APP_FILTER_STORAGE || 'localStorage', // url, localStorage
    /**
     * I18N
     */
    I18N_LOCALE: process.env.VUE_APP_I18N_LOCALE || 'en',
    I18N_LOCALE_FALLBACK: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    /**
     * Freshdesk
     */
    FRESHDESK_WIDGET_ID: process.env.FRESHDESK_WIDGET_ID || null,
    /**
     * Ideal Postcodes
     */
    IDEAL_POSTCODES_API_KEY: process.env.VUE_APP_IDEAL_POSTCODES_API_KEY || null,
    /**
     * Sentry DSN
     */
    SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN || null,
    /**
     * Sentry Auth Token
     */
    SENTRY_AUTH_TOKEN: process.env.VUE_APP_SENTRY_AUTH_TOKEN || null,
    /**
     * Sentry Organisation Slug
     */
    SENTRY_ORGANISATION_SLUG: process.env.VUE_APP_SENTRY_ORGANISATION_SLUG || 'growthinvest',
    /**
     * Sentry Project Slug
     */
    SENTRY_PROJECT_SLUG: process.env.VUE_APP_SENTRY_PROJECT_SLUG || 'frontend',
    /**
     * Sentry Browser Tracing
     */
    SENTRY_BROWSER_TRACING: [1, '1', true, 'true'].includes(process.env.VUE_APP_SENTRY_BROWSER_TRACING),
    /**
     * Sentry Browser Profiling
     */
    SENTRY_BROWSER_PROFILING: [1, '1', true, 'true'].includes(process.env.VUE_APP_SENTRY_BROWSER_PROFILING),
    /**
     * Sentry Session Replay
     */
    SENTRY_SESSION_REPLAY: [1, '1', true, 'true'].includes(process.env.VUE_APP_SENTRY_SESSION_REPLAY),
    /**
     * Allotment Tolerance
     */
    ALLOTMENT_AMOUNT_TOLERANCE: parseFloat(process.env.VUE_APP_ALLOTMENT_AMOUNT_TOLERANCE) || 0.01
};

export default config;
