import { BaseModel } from '@/models';
import IntroducerApi from '@/api/IntroducerApi';

export class Introducer extends BaseModel {
    static name = 'Introducer';
    static entity = 'introducers';
    static Api = IntroducerApi;

    static fields() {
        return {
            id: this.attr(null),
            name: this.string('')
        };
    }
}

export default Introducer;
