import { BaseModel, Fund, Investor, Adviser } from '@/models';
import TrancheApi from '@/api/TrancheApi';

export class Tranche extends BaseModel {
    static name = 'Tranche';
    static entity = 'tranches';
    static Api = TrancheApi;

    static fields() {
        return {
            id: this.attr(null),
            cash_balance: this.attr(null).nullable(),
            total_investment: this.attr(null).nullable(),
            invested_on: this.string(null).nullable(),
            reconciled: this.boolean(false).nullable(),
            discretionary: this.boolean(true).nullable(),
            multi_investment: this.boolean(false).nullable(),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id'),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id')
        };
    }
}

export default Tranche;
