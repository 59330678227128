import { Collection, FeePlan } from '@/models';
import i18n from '@/lib/i18n';
import FeeTaxTypeEnum from '@/enums/fee/taxType';

export class SystemFeePlanCollection extends Collection {
    static type = 'SystemFeePlanCollection';
    static endpoint = FeePlan.api.index;
    static model = FeePlan;

    get default_headers() {
        return ['id', 'name', 'tax_type', 'control'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '18rem'
            }),
            this.createHeader('tax_type', i18n.t('tax_type'), {
                format: 'enum',
                filterable: true,
                enum: FeeTaxTypeEnum,
                enumA11yKey: 'enums.fee_tax_type',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '14rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '100%',
                minWidth: '7rem',
                persistent: true
            })
        ];
    }
}

export default SystemFeePlanCollection;
