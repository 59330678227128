import permissions from '@/enums/auth/permission';
import i18n from '@/lib/i18n';

export default [
    {
        path: '/shortlists',
        component: () => import('@/views/Index.vue'),
        children: [
            {
                path: '/shortlists',
                name: 'Shortlists',
                component: () =>
                    import(
                        /* webpackChunkName: "shortlists" */
                        '@/views/Shortlists.vue'
                    ),
                meta: {
                    title: i18n.t('_page.shortlists.heading'),
                    heading: i18n.t('_page.shortlists.heading'),
                    description: i18n.t('_page.shortlists.description'),
                    permissions: [permissions.INVESTMENT_BOOK],
                    organisations: ['ADVISER', 'INVESTOR']
                }
            },
            {
                path: '/shortlist',
                name: 'Shortlist',
                component: () =>
                    import(
                        /* webpackChunkName: "shortlists" */
                        '@/views/Shortlist.vue'
                    ),
                meta: {
                    title: i18n.t('shortlist'),
                    heading: i18n.t('shortlist'),
                    organisations: ['ADVISER', 'INVESTOR']
                }
            }
        ]
    }
];
