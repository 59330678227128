import { Organisation, BusinessStage, InvestmentStrategy, VctStats, CompanyNews, Product } from '@/models';
import MarketCapSource from '@/enums/marketCapSource';
import CompanyApi from '@/api/CompanyApi';

export class Company extends Organisation {
    static name = 'Company';
    static entity = 'companies';
    static Api = CompanyApi;

    static fields() {
        return {
            ...super.fields(),
            trading_name: this.string(null).nullable(),
            overview: this.attr(null).nullable(),
            market_cap_recorded: this.attr(this.defaultMoney).nullable(),
            market_cap_source: this.enum(MarketCapSource).nullable(),
            target_return: this.string(null).nullable(),
            single_company: this.boolean(null).nullable(),
            is_vct_fund: this.boolean(null).nullable(),
            listed_status: this.boolean(null).nullable(),
            business_stage_id: this.string(null).nullable(),
            business_stage: this.belongsTo(BusinessStage, 'business_stage_id'),
            investment_strategy_id: this.string(null).nullable(),
            investment_strategy: this.belongsTo(InvestmentStrategy, 'investment_strategy_id'),
            vct_stats: this.model(VctStats).nullable(),
            news_items: this.hasMany(CompanyNews, 'company_id'),
            products: this.hasMany(Product, 'company_id'),
            downloads: this.attr(null)
        };
    }
}

export default Company;
