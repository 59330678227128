const EsignParticipantStatus = Object.freeze({
    DRAFT: 'DRAFT',
    SENT: 'SENT',
    VIEWED: 'VIEWED',
    DECLINED: 'DECLINED',
    SIGNED: 'SIGNED',
    EXPIRED: 'EXPIRED',
    PENDING_PHYSICAL: 'PENDING_PHYSICAL',
    AUTORESPONDER: 'AUTORESPONDER',
    CANCELLED: 'CANCELLED'
});

export default EsignParticipantStatus;
