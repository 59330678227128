import { BaseModel } from '@/models';
import DeclarationApi from '@/api/DeclarationApi';

export class Declaration extends BaseModel {
    static name = 'Declaration';
    static entity = 'declarations';
    static Api = DeclarationApi;

    static fields() {
        return {
            id: this.attr(null),
            investor_type: this.string(null).nullable(),
            advised: this.boolean(null).nullable(),
            sort_order: this.number(0),
            declaration: this.attr(null).nullable()
        };
    }
}

export default Declaration;
