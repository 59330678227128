import { BaseModel } from '@/models';

export function cleanModelData(v) {
    if (v === null) {
        return null;
    }

    if (typeof v === 'string') {
        return v;
    }

    if (typeof v === 'number') {
        return v;
    }

    if (typeof v === 'boolean') {
        return v;
    }

    if (v instanceof File) {
        return v;
    }

    if (v instanceof BaseModel) {
        v = v.$toPayload();
    }

    if (typeof v === 'object' && v && '$deleted' in v && v.$deleted) {
        return undefined;
    }

    if (Array.isArray(v)) {
        return v.map(v => cleanModelData(v)).filter(v => v !== undefined);
    }

    if (typeof v === 'object' && v && 'amount' in v && 'currency' in v) {
        if (v.amount === null || v.amount === undefined) {
            return null;
        }
    }

    if (typeof v === 'object' && 'content' in v && Array.isArray(v.content) && !('type' in v)) {
        v.type = 'doc';
    }

    if (typeof v === 'object') {
        return Object.keys(v).reduce((acc, key) => {
            if (!key.startsWith('$') || key === '$deleted') {
                const clean = cleanModelData(v[`${key}`]);
                if (clean !== undefined) {
                    acc[`${key}`] = clean;
                }
            }
            return acc;
        }, {});
    }

    return v;
}

export default cleanModelData;
