import { BaseModel, InvestorAdviserRelationship, PhoneNumber, Address, BankAccount } from '@/models';
import InvestorCategoryEnum from '@/enums/investor/category';
import clock from '@/lib/clock';
import InvestorApi from '@/api/InvestorApi';

export class Client extends BaseModel {
    static name = 'Client';
    static entity = 'investors';
    static Api = InvestorApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.attr(null),
            name: this.string().nullable(),
            gi_ref: this.string(null).nullable(),
            email: this.string(null).nullable(),
            dob: this.string(null).nullable(),
            national_insurance: this.string(null).nullable(),
            phone_numbers: this.modelList(PhoneNumber).nullable(),
            addresses: this.modelList(Address).nullable(),
            bank_accounts: this.modelList(BankAccount).nullable(),
            nationality: this.attr(null).nullable(),
            domiciled: this.attr(null).nullable(),
            tax_cert_location: this.string(null).nullable(),
            us_person: this.boolean(null).nullable(),
            politically_exposed: this.boolean(null).nullable(),
            can_login: this.boolean(null).nullable(),
            category: this.enum(InvestorCategoryEnum, InvestorCategoryEnum.RETAIL_RESTRICTED).nullable(),
            category_expires_on: this.string(null).nullable(),
            adviser_accounts: this.modelList(InvestorAdviserRelationship, null).nullable()
        };
    }

    get adviser_ids() {
        if (!this.adviser_accounts) {
            return [];
        }

        return this.adviser_accounts.map(aa => aa.adviser_id || aa.adviser.id);
    }

    get max_categorisation_expiry_date() {
        return clock().add(1, 'years').format('YYYY-MM-DD');
    }
}

export default Client;
