import BaseApi from '@/api/BaseApi';
import { Proposal } from '@/models';

export class ShortlistApi extends BaseApi {
    static resource = 'shortlists';

    get endpoints() {
        return {
            ...super.endpoints,
            requestIllustration: this.requestIllustration.bind(this),
            putEntry: this.putEntry.bind(this),
            proposeEntry: this.proposeEntry.bind(this),
            removeEntry: this.removeEntry.bind(this)
        };
    }

    _filterSaveData(data) {
        if (data && Array.isArray(data.entries)) {
            data.entries = data.entries.map(item => {
                if (Array.isArray(item.signatories)) {
                    item.signatories = item.signatories.map(signatory => ({
                        ...signatory,
                        role: signatory.signature_key,
                        cc: !signatory.signature_key,
                        order: !signatory.signature_key ? null : signatory.order
                    }));
                }
                return item;
            });
        }

        return data;
    }

    _filterResponseData(data) {
        data = super._filterResponseData(data);

        if (data && Array.isArray(data)) {
            data = data.map(item => this._filterResponseData(item));
        }

        if (data && Array.isArray(data.entries)) {
            data.entries = data.entries.map(item => {
                if (Array.isArray(item.signatories)) {
                    item.signatories = item.signatories.map(signatory => ({
                        ...signatory,
                        cc: !signatory.role,
                        order: !signatory.role ? null : signatory.order,
                        signature_key: signatory.role,
                        organisation_role: !signatory.role
                            ? null
                            : signatory.role === 'investor'
                            ? 'INVESTOR'
                            : 'ADVISER'
                    }));
                }
                return item;
            });
        } else if (Array.isArray(data.signatories)) {
            data.signatories = data.signatories.map(signatory => ({
                ...signatory,
                cc: !signatory.role,
                order: !signatory.role ? null : signatory.order,
                signature_key: signatory.role,
                organisation_role: !signatory.role ? null : signatory.role === 'investor' ? 'INVESTOR' : 'ADVISER'
            }));
        }

        return data;
    }

    _filterGetResponseData(data) {
        if (data && Array.isArray(data.entries)) {
            data.entries = data.entries.map(item => {
                item.offer_id = item.offer.id;
                item.shortlist_id = data.id;
                item.client_id = data.investor.id;
                return item;
            });
        }

        return data;
    }

    async requestIllustration(shortlistId, message) {
        const response = await BaseApi.api.post(`${this.base_url}/${shortlistId}/request-illustration`, { message });
        return this._respond(response);
    }

    async putEntry(entry, return_entry = true) {
        for (const key in entry) {
            if (entry[`${key}`] && typeof entry[`${key}`] === 'object' && entry[`${key}`].amount === null) {
                entry[`${key}`] = null;
            }
        }

        if (entry && Array.isArray(entry.signatories)) {
            entry.signatories = entry.signatories.map(signatory => ({
                ...signatory,
                role: signatory.signature_key,
                cc: !signatory.signature_key,
                order: !signatory.signature_key ? null : signatory.order
            }));
        }

        const response = await BaseApi.api.put(`${this.base_url}/${entry.shortlist_id}/entry`, entry, {
            params: { return_entry: return_entry ? true : false }
        });

        return this._respond(response, () => {
            Proposal.delete(entry.id);

            response.data = this._filterResponseData(response.data);
            this._clearCollection('Shortlist');
            this._clearCollection('Proposal');
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL, Proposal);
        });
    }

    async proposeEntry(entry, config = {}) {
        if (entry && Array.isArray(entry.signatories)) {
            entry.signatories = entry.signatories.map(signatory => ({
                ...signatory,
                role: signatory.signature_key,
                cc: !signatory.signature_key,
                order: !signatory.signature_key ? null : signatory.order
            }));
        }

        const response = await BaseApi.api.post(`${this.base_url}/entry/${entry.id}/propose`, null, config);

        return this._respond(response, () => {
            Proposal.delete(entry.id);
            response.data = this._filterResponseData(response.data);
            this._clearCollection('Shortlist');
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async removeEntry(id, shortlistId) {
        const response = await BaseApi.api.remove(`${this.base_url}/entry/${id}`);

        return this._respond(response, () => {
            Proposal.delete(id);
            this._removeItemInCollections(id);
            this._clearCollection('Shortlist');
            this.model.delete(shortlistId);
        });
    }
}

export default ShortlistApi;
