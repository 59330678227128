const runWithTimer = async (fn, callback = () => {}) => {
    const timer = {
        start: Date.now(),
        elapsed: 0
    };

    callback(timer);

    const intervalId = setInterval(() => {
        timer.elapsed = Math.floor((Date.now() - timer.start) / 1000);
        callback(timer);
    }, 1000);

    try {
        return await fn();
    } finally {
        clearInterval(intervalId);
    }
};

export default runWithTimer;
