let AppTooltipElement = null;

const createTooltip = () => {
    if (!AppTooltipElement) {
        AppTooltipElement = document.createElement('div');
        AppTooltipElement.style.display = 'none';
        AppTooltipElement.className = 'app-tooltip';
        document.body.appendChild(AppTooltipElement);
    }
    return AppTooltipElement;
};

const setTooltipContent = (el, content) => {
    if (content) {
        el.dataset.tooltipContent = content;
    } else {
        delete el.dataset.tooltipContent;
    }
};

const updateTooltipContent = el => {
    AppTooltipElement.textContent = el.dataset.tooltipContent;
};

const updateTooltipPosition = (x, y) => {
    AppTooltipElement.style.left = `${x}px`;
    AppTooltipElement.style.top = `${y}px`;
};

const displayTooltip = () => {
    if (AppTooltipElement.textContent) {
        AppTooltipElement.style.display = 'block';
    }
};

const hideTooltip = () => {
    AppTooltipElement.style.display = 'none';
};

export default {
    bind(el, binding) {
        setTooltipContent(el, binding.value);
        createTooltip();

        const onMouseEnter = () => {
            if (el.dataset.tooltipContent) {
                updateTooltipContent(el);
                displayTooltip();
            }
        };

        const onMouseMove = event => {
            updateTooltipPosition(event.clientX + 10, event.clientY + 10);
        };

        const onMouseLeave = event => {
            const target = event.target;

            if (target instanceof Element) {
                const parentTooltipEl = target.parentNode.closest('[data-tooltip-content]');

                if (parentTooltipEl && parentTooltipEl.dataset.tooltipContent) {
                    updateTooltipContent(parentTooltipEl);
                    return;
                }
            }

            hideTooltip();
        };

        el.addEventListener('mouseenter', onMouseEnter);
        el.addEventListener('mousemove', onMouseMove);
        el.addEventListener('mouseleave', onMouseLeave);

        el._tooltipHandlers = { onMouseEnter, onMouseMove, onMouseLeave };
    },

    update(el, binding) {
        if (binding.value === null) {
            hideTooltip();
        }

        setTooltipContent(el, binding.value);
        updateTooltipContent(el);
    },

    unbind(el) {
        const { onMouseEnter, onMouseMove, onMouseLeave } = el._tooltipHandlers;

        el.removeEventListener('mouseenter', onMouseEnter);
        el.removeEventListener('mousemove', onMouseMove);
        el.removeEventListener('mouseleave', onMouseLeave);

        if (AppTooltipElement) {
            document.body.removeChild(AppTooltipElement);
            AppTooltipElement = null;
        }
    }
};
