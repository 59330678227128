const FeeSelectorEnum = Object.freeze({
    PERCENTAGE: 'PERCENTAGE',
    MONEY: 'MONEY',
    MULTIPLIER: 'MULTIPLIER',
    NUMERIC: 'NUMERIC',
    BOOLEAN: 'BOOLEAN',
    TEXT: 'TEXT'
});

export default FeeSelectorEnum;
