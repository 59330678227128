import { Organisation } from '@/models';
import CustodianApi from '@/api/CustodianApi';

export class Custodian extends Organisation {
    static name = 'Custodian';
    static entity = 'custodians';
    static Api = CustodianApi;

    static fields() {
        return {
            ...super.fields(),
            accounts: this.attr([]).nullable(),
            funds: this.attr([]).nullable(),
            vcts: this.attr([]).nullable(),
            offers: this.attr([]).nullable()
        };
    }
}

export default Custodian;
