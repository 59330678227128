import { BaseModel, User } from '@/models';
import OrganisationTypeEnum from '@/enums/organisation/type';
import EsignParticipantRole from '@/enums/esign/participantRole';
import EsignParticipantStatus from '@/enums/esign/participantStatus';
import i18n from '@/lib/i18n';

export class EsignAgreementParticipant extends BaseModel {
    static entity = 'esignAgreementParticipant';

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            external_id: this.string(null).nullable(),
            participant_set_id: this.string(null).nullable(),
            email: this.string(null).nullable(),
            name: this.string(null).nullable(),
            role: this.enum(EsignParticipantRole).nullable(),
            old_role: this.string(null).nullable(),
            order: this.number(1).nullable(),
            user_id: this.string(null).nullable(),
            user: this.belongsTo(User, 'user_id'),
            status: this.enum(EsignParticipantStatus).nullable(),
            old_status: this.string(null).nullable(),
            organisation_role: this.enum(OrganisationTypeEnum).nullable(),
            sent_at: this.string(null).nullable(),
            first_viewed_at: this.string(null).nullable(),
            viewed_at: this.string(null).nullable(),
            signed_at: this.string(null).nullable(),
            autoresponder_message: this.string(null).nullable(),
            //
            signature_key: this.string(null).nullable(),
            cc: this.boolean(true).nullable()
        };
    }

    get is_valid() {
        return [this.role, this.organisation_role].every(v => v);
    }

    get computed_name() {
        const name = [this.signature_key, this.role, this.organisation_role].filter(v => v).join(' - ');

        return name || i18n.t('required_signatory');
    }
}

export default EsignAgreementParticipant;
