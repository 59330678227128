const FeeTypeEnum = Object.freeze({
    INITIAL: 'INITIAL',
    ANNUAL_MANAGEMENT: 'ANNUAL_MANAGEMENT',
    CUSTODY: 'CUSTODY',
    DEALING: 'DEALING',
    OTHER_INITIAL: 'OTHER_INITIAL',
    OTHER_ONGOING: 'OTHER_ONGOING',
    PERFORMANCE: 'PERFORMANCE',
    EXIT: 'EXIT',
    INVESTEE_INITIAL: 'INVESTEE_INITIAL',
    INVESTEE_ANNUAL_MANAGEMENT: 'INVESTEE_ANNUAL_MANAGEMENT',
    INVESTEE_ADMINISTRATION: 'INVESTEE_ADMINISTRATION',
    INVESTEE_DEALING: 'INVESTEE_DEALING',
    INVESTEE_MENTORING: 'INVESTEE_MENTORING',
    INVESTEE_OTHER: 'INVESTEE_OTHER',
    INVESTEE_BOARD: 'INVESTEE_BOARD',
    DEPLOYMENT_FACTOR: 'DEPLOYMENT_FACTOR',
    AIC_ONGOING: 'AIC_ONGOING',
    EXPENSE_CAP: 'EXPENSE_CAP'
});

export default FeeTypeEnum;
