import { Model, Relation, BelongsTo, BelongsToMany } from '@vuex-orm/core';
import Auth from '@/models/Auth';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import getValidator from '@/lib/helpers/getValidator';
import getTaxYear from '@/lib/helpers/getTaxYear';
import removeNull from '@/api/lib/helpers/removeNull';
import FilterOperator from '@/enums/filter/operator';
import ApiResponseFormatEnum from '@/enums/api/responseFormat';
import $m from '@/lib/money';

export const looksLikeBaseModel = value => {
    if (value instanceof BaseModel) {
        return true;
    }

    if (!value) {
        return false;
    }

    if (typeof value !== 'object') {
        return false;
    }

    return '$id' in value && '$form' in value && '$responseFormat' in value;
};

export class BaseModel extends Model {
    static looksLikeBaseModel;
    static runSetup = false;
    static cacheOriginal = false;

    constructor(...args) {
        super(...args);

        if (this.constructor.runSetup) {
            this.setup();
        } else if (this.constructor.cacheOriginal) {
            this.$original = this.$toPayload();
        }
    }

    async setup(options = {}) {
        this.beforeSetup();
        const setupId = parseInt(this.$setupId);
        try {
            this.$setup = this.setupLogic(options, setupId);
            await this.$setup;

            if (this.constructor.cacheOriginal) {
                this.$original = this.$toPayload();
            }
        } catch (error) {
            if (error.name !== 'SetupError') {
                throw error;
            }
        } finally {
            this.afterSetup();
        }
    }

    beforeSetup() {
        this.$setupId = this.$setupId + 1;
        this.$setupRunning = true;
    }

    afterSetup() {
        this.$setup = null;
        this.$setupRunning = false;
        this.$setupLoaded = true;
    }

    // eslint-disable-next-line no-unused-vars
    async setupLogic(options, setupId) {
        throw new ReferenceError(
            `'setupLogic(options = {}, setupId = 0)' is not defined on model ${this.constructor.name}`
        );
    }

    stopSetup(setupId = 0) {
        if (this.$setupId > setupId) {
            const error = new Error('Setup has been superseded');
            error.name = 'SetupError';
            throw error;
        }
    }

    get $dirty() {
        if (!this.$original) {
            return false;
        }

        const payload = this.$toPayload();
        return Object.keys(payload).some(key => payload[`${key}`] !== this.$original[`${key}`]);
    }

    //

    static Auth = Auth;
    get Auth() {
        return Auth;
    }

    static defaultMoney = { amount: null, currency: null };
    get defaultMoney() {
        return BaseModel.defaultMoney;
    }

    static ApiResponseFormatEnum = ApiResponseFormatEnum;
    get ApiResponseFormatEnum() {
        return ApiResponseFormatEnum;
    }

    static $m = $m;
    get $m() {
        return $m;
    }

    toMoney(amount = 0, fallback = 0) {
        if (isNaN(amount) || amount === null) {
            return fallback;
        }
        if (amount && typeof amount === 'object' && 'amount' in amount) {
            return { currency: amount.currency || this._currency, amount };
        }
        return { currency: this._currency || 'GBP', amount };
    }

    fromMoney(money, fallback = 0) {
        if (money === null) {
            return fallback;
        } else if (typeof money === 'number') {
            return money;
        } else if (typeof money === 'string') {
            return parseFloat(money);
        } else if (typeof money === 'object' && money && !isNaN(money.amount)) {
            return money.amount;
        }

        return fallback;
    }

    static fields() {
        return {
            $responseFormat: this.enum(ApiResponseFormatEnum, 0),
            //
            $setupRunning: this.boolean(false),
            $setupLoaded: this.boolean(false),
            $setupId: this.number(0),
            $setup: this.attr(null),
            $original: this.attr(null),
            //
            $hydrated: this.attr([]),
            $loading: this.boolean(false),
            $creating: this.boolean(false),
            $editing: this.boolean(false),
            $deleting: this.boolean(false),
            $deleted: this.boolean(false),
            $updating: this.boolean(false),
            $removing: this.boolean(false),
            $copying: this.boolean(false),
            $saving: this.attr([]),
            $form: this.attr({}),
            $table_values: this.attr({})
        };
    }

    static findBy(fields = null) {
        if (Array.isArray(fields)) {
            fields = fields.reduce((acc, field) => {
                acc[`${field}`] = this[`${field}`];
                return acc;
            }, {});
        } else if (typeof fields === 'string') {
            fields = { id: fields };
        }

        if (!fields || typeof fields !== 'object' || Object.keys(fields).length === 0) {
            throw new Error(`'fields' must be an array or object with at least 1 field`);
        }

        const query = this.query();

        for (let field in fields) {
            query.where(field, fields[`${field}`]);
        }

        query.withAllRecursive();

        return query.first();
    }

    static validation() {
        return {};
    }

    static Api = null;
    static get api() {
        if (!this.Api) {
            throw new ReferenceError(`'Api' is not defined on model ${this.name}`);
        }

        return new this.Api(this).methods;
    }

    static mock() {
        return {};
    }

    static enum(enumObject, defaultValue = null) {
        const validator = value => {
            if (Object.values(enumObject).includes(value)) {
                return value;
            }
            return null;
        };

        return this.attr(defaultValue, validator);
    }

    static enumList(enumObject, defaultValue = null) {
        const validator = value => {
            if (Object.values(enumObject).includes(value)) {
                return value;
            }
            return null;
        };

        return this.attr(defaultValue, value => {
            if (value instanceof Array) {
                return value.map(item => validator(item)).filter(v => v);
            }

            return null;
        });
    }

    static as_model(value, modelClass) {
        if (value instanceof modelClass) {
            return value;
        }

        if (value instanceof Object) {
            return new modelClass(value);
        }

        return null;
    }

    static model(modelClass, defaultValue) {
        defaultValue = defaultValue === undefined ? new modelClass() : defaultValue;
        return this.attr(defaultValue, value => this.as_model(value, modelClass));
    }

    static modelList(modelClass, defaultValue = null) {
        return this.attr(defaultValue, value => {
            if (value instanceof Array) {
                return value.map(item => this.as_model(item, modelClass)).filter(v => v);
            }

            return null;
        });
    }

    $resetForm() {
        this.$form = this.$toPayload();
    }

    async $hydrate() {
        return await this.constructor.$hydrate(this);
    }

    static async $hydrate(id) {
        const model =
            id instanceof BaseModel ? await this.$get(id.id, { type: 'FULL' }) : await this.$get(id, { type: 'FULL' });

        const relations = model.$getRelationFields();

        for (const fieldKey in relations) {
            const relation = relations[`${fieldKey}`];
            const field = model[`${fieldKey}`];

            try {
                await this.hydrateRelation(relation, field);
            } catch (error) {
                console.error(error);
            }
        }

        return await this.$get(model.id, { type: 'FULL' });
    }

    $getRelationFields() {
        return this.constructor.getRelationFields(this);
    }

    static getRelationFields(model = null) {
        model = model || this;

        return pickBy(model.constructor.fields(), value => {
            return value instanceof Relation;
        });
    }

    static async hydrateRelation(relation, field) {
        if (relation instanceof BelongsTo) {
            const id = field && field instanceof Object && field.id ? field.id : null;
            if (id) {
                await relation.parent.$get(id);
            }
        } else if (relation instanceof BelongsToMany) {
            for (const item of field) {
                const id = item && item instanceof Object && item.id ? item.id : null;
                if (id) {
                    await relation.related.$get(item.id);
                }
            }
        }
    }

    $toPayload(data = null) {
        data = data || this;

        let payload = {};

        for (const fieldKey in data) {
            if (fieldKey.startsWith('$') && fieldKey !== '$deleted') {
                continue;
            }

            let value = data[`${fieldKey}`];

            if (value && Array.isArray(value)) {
                value = value.map(item => {
                    if (item instanceof BaseModel) {
                        return item.$toPayload();
                    } else if (looksLikeBaseModel(item)) {
                        return this.$toPayload(item);
                    }

                    return item;
                });
            } else if (value && typeof value === 'object') {
                if (value instanceof BaseModel) {
                    value = value.$toPayload();
                } else if (looksLikeBaseModel(value)) {
                    return this.$toPayload(value);
                }

                if (!Object.keys(removeNull(value)).length) {
                    value = null;
                }
            }

            payload[`${fieldKey}`] = value;
        }

        payload = this.$filterPayload(payload);

        return !Object.keys(payload).length ? null : payload;
    }

    $filterPayload(payload) {
        return payload;
    }

    $getValidationFields(validationKey) {
        const validations = this.constructor.validation();

        if (!validationKey) {
            throw new ReferenceError('Invalid validation key: undefined');
        }

        if (!(validationKey in validations)) {
            throw new ReferenceError(`Invalid validation key: ${validationKey}`);
        }

        return validations[`${validationKey}`];
    }

    $validate(validationKey) {
        if (validationKey) {
            return this.$validateByKey(validationKey);
        }

        const validations = this.constructor.validation();

        const results = {};

        for (const key in validations) {
            results[`${key}`] = this.$validateByKey(key);
        }

        return results;
    }

    $validateByKey(validationKey) {
        const validations = this.constructor.validation();

        if (!(validationKey in validations)) {
            throw new ReferenceError(`Invalid validation key: ${validationKey}`);
        }

        let results = {};

        for (const field in validations[`${validationKey}`]) {
            const rules = validations[`${validationKey}`][`${field}`];
            results = this.$getValidationResult(rules, field, results);
        }

        return results;
    }

    $getValidationResult(rules, field, results = {}) {
        if (Array.isArray(rules)) {
            for (const rule of rules) {
                results = { ...results, ...this.$getValidationResult(rule, field, results) };
            }
        } else {
            let result;

            if (typeof rules === 'function') {
                result = rules(this[`${field}`]);
            } else if (typeof rules === 'string' && rules.includes(':')) {
                const validationDefinition = rules.split(':');
                const validator = getValidator(validationDefinition[0]);

                let args = [];
                if (validationDefinition.length > 1) {
                    for (const arg of validationDefinition[1].split(',')) {
                        if (arg === '*') {
                            args.push(this);
                        } else {
                            args.push(this[`${arg}`]);
                        }
                    }
                } else {
                    args = [this[`${field}`]];
                }

                result = validator(...args);
            }

            if (result === true || typeof result === 'string') {
                results[`${field}`] = result;
            } else if (Array.isArray(result)) {
                if (result.length > 0) {
                    for (const r of result) {
                        results[`${r.field}`] = r.result;
                    }
                } else {
                    results[`${field}`] = true;
                }
            }
        }
        return results;
    }

    $getValidationPercentage(validationKey) {
        let results = [];

        if (validationKey) {
            results = Object.values(this.$validateByKey(validationKey));
        } else {
            const validations = this.constructor.validation();

            for (const key in validations) {
                results = [...results, ...Object.values(this.$validateByKey(key))];
            }
        }

        const valid = results.filter(res => res === true);
        const percentage = (valid.length / results.length) * 100;

        return percentage.toFixed(0);
    }

    // API Methods

    // GET
    async $get(options = {}) {
        this.$loading = true;

        return await this.constructor.$get(this.id, options).finally(() => {
            this.$loading = false;
        });
    }
    static async $get(id, options = {}) {
        if (!id) {
            throw new ReferenceError(`Invalid ID when calling $get on model ${this.name}`);
        }

        options = {
            force: options.force || false,
            type: options.type || 'DETAIL',
            params: options.params || {}
        };

        if (!options.force) {
            let modelInstance = this.findBy(id);

            if (modelInstance && modelInstance.$responseFormat >= this.ApiResponseFormatEnum[options.type]) {
                return modelInstance;
            }
        }

        const params = {
            ...options.params
        };

        if (options.type === 'FULL') {
            params.full_object = true;
        } else if (options.type === 'SUMMARY') {
            params.summary_object = true;
        }

        await this.api.get(id, params, {
            persistent: options.force,
            responseFormat: this.ApiResponseFormatEnum[options.type]
        });

        return this.findBy(id);
    }

    // POST
    async $create() {
        this.$creating = true;

        return await this.constructor.$create(this.$toPayload()).finally(() => {
            this.$creating = false;
        });
    }
    static async $create(data = null) {
        const response = await this.api.post(data);

        let id = null;

        if (response?.data?.id) {
            id = response.data.id;
            await this.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });
        } else if (response?.data?.created_id) {
            id = response.data.created_id;
            await this.api.get(id);
        } else {
            throw new ReferenceError(`Invalid ID when calling $create on model ${this.name}`);
        }

        return this.query()
            .whereId(response?.data?.id || response?.data?.created_id)
            .withAllRecursive()
            .first();
    }

    // PUT/PATCH
    async $update(data = null) {
        this.$updating = true;

        const method = data ? 'PATCH' : 'PUT';
        data = data || this.$toPayload();

        this.$saving = Object.keys(data);

        return await this.constructor.$update(this.id, method, data).finally(() => {
            this.$updating = false;
            this.$saving = [];
        });
    }
    static async $update(id, method = 'PUT', data = null) {
        if (!id) {
            throw new ReferenceError(`Invalid ID when calling $update on model ${this.name}`);
        }

        let response = null;

        if (method === 'PUT') {
            response = await this.api.put(data);
        } else if (method === 'PATCH') {
            response = await this.api.patch(id, data);
        } else {
            throw new Error(`${this.name} method not found when trying to update`);
        }

        if (response?.data?.id) {
            await this.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });
        } else if (response?.data?.created_id) {
            await this.api.get(id);
        }

        return this.findBy(id);
    }

    // DELETE
    async $remove() {
        this.$removing = true;

        return await this.constructor.$remove(this.id).finally(() => {
            this.$removing = false;
        });
    }
    static async $remove(id) {
        if (!id) {
            throw new ReferenceError(`Invalid ID when calling $remove on model ${this.name}`);
        }

        return await this.api.remove(id);
    }

    // COPY
    async $copy() {
        this.$copying = true;

        return await this.constructor.$copy(this.id).finally(() => {
            this.$copying = false;
        });
    }
    static async $copy(id) {
        if (!id) {
            throw new ReferenceError(`Invalid ID when calling $copy on model ${this.name}`);
        }

        const response = await this.api.copy(id);

        id = null;

        if (response?.data?.id) {
            id = response.data.id;
            await this.insertOrUpdate({ data: { ...response.data, $responseFormat: 20 } });
        } else if (response?.data?.created_id) {
            id = response.data.created_id;
            await this.api.get(id);
        } else {
            throw new ReferenceError(`Invalid ID when calling $copy on model ${this.name}`);
        }

        return this.query()
            .whereId(response?.data?.id || response?.data?.created_id)
            .withAllRecursive()
            .first();
    }

    static async search(criteria, filters = {}, config = {}) {
        return (await this.api.search(criteria, filters, config)).data;
    }
    static abortSearch(group) {
        return new this.Api().abortSearch(group);
    }

    is(model) {
        return isEqual(this.$toJson(), this.constructor.getModelPayload(model));
    }

    get is_valid() {
        throw new ReferenceError(`'get is_valid()' is not defined on model ${this.constructor.name}`);
    }

    get computed_name() {
        throw new ReferenceError(`'get computed_name()' is not defined on model ${this.constructor.name}`);
    }

    getOperators(operators = []) {
        return Object.values(FilterOperator).filter(op => operators.includes(op.value));
    }

    static getModelPayload(model) {
        if (!model) {
            return null;
        }

        if (typeof model.$toJson === 'function') {
            try {
                return model.$toJson();
            } catch (error) {
                return model;
            }
        }

        return model;
    }

    clone(deep = false) {
        const payload = this.constructor.getModelPayload(this);
        const newInstance = new this.constructor(payload);

        if (!deep) {
            return newInstance;
        }

        for (let key in this) {
            const prop = this[`${key}`];

            if (Array.isArray(prop)) {
                newInstance[`${key}`] = prop.map(item => {
                    if (item && typeof item === 'object' && item.constructor !== Object) {
                        if (typeof item.clone === 'function') {
                            return item.clone();
                        } else {
                            return new item.constructor(item);
                        }
                    }
                    return item;
                });
            } else if (prop && typeof prop === 'object' && prop.constructor !== Object) {
                if (typeof prop.clone === 'function') {
                    newInstance[`${key}`] = prop.clone();
                } else {
                    newInstance[`${key}`] = new prop.constructor(prop);
                }
            }
        }

        return newInstance;
    }

    getTaxYear(date = null, options = {}) {
        return getTaxYear(date, options);
    }
}

export default BaseModel;
