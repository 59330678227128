export default {
    bind(el, binding) {
        const { key, properties } = binding.value;

        const syncStyles = () => {
            requestAnimationFrame(() => {
                if (key) {
                    properties.forEach(property => {
                        const computedStyle = window.getComputedStyle(key);
                        const newValue = computedStyle[`${property}`];

                        // Only update if there's a difference to prevent unnecessary loops
                        if (el.style[`${property}`] !== newValue) {
                            el.style[`${property}`] = newValue;
                        }
                    });
                }
            });
        };

        const resizeObserver = new ResizeObserver(syncStyles);
        resizeObserver.observe(key);

        syncStyles();

        el.__resizeObserver__ = resizeObserver;
    },
    unbind(el) {
        if (el.__resizeObserver__) {
            el.__resizeObserver__.disconnect();
            delete el.__resizeObserver__;
        }
    }
};
