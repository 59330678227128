import { BaseModel } from '@/models';
import MediaApi from '@/api/MediaApi';
import MediaTypeEnum from '@/enums/media/type';
import MediaFormatEnum from '@/enums/media/format';
import config from '@/config';
import kebabCase from 'lodash/kebabCase';

import toImage from '@/lib/helpers/toImage';
import toNumber from '@/lib/helpers/toNumber';
import getFileTypeIcon from '@/lib/helpers/getFileTypeIcon';
import download from '@/lib/helpers/download';

import request from '@/api/lib/request';
import { attachHeaders } from '@/api/lib/request';

import YouTubeUrl from '@/lib/validators/YouTubeUrlValidator';

export class Media extends BaseModel {
    static name = 'Media';
    static entity = 'media';
    static Api = MediaApi;

    static fields() {
        return {
            id: this.attr(null),
            library_type: this.enum(MediaTypeEnum).nullable(),
            name: this.string(null).nullable(),
            description: this.attr(null).nullable(),
            alt_text: this.string(null).nullable(),
            download: this.boolean(true),
            format: this.enum(MediaFormatEnum),
            url: this.string(null).nullable(), // The returned url is the link to the CDN file object
            external_url: this.string(null).nullable(),
            created_at: this.string(null).nullable(),
            created_by_id: this.string(null).nullable(),
            created_by: this.attr(null).nullable(),
            file: this.attr(null).nullable(),
            file_awaiting_upload: this.attr(null).nullable()
        };
    }

    async $upload(file) {
        file = file || this.file_awaiting_upload;

        if (!file) {
            return;
        }

        if (!this.id) {
            const response = await this.constructor.api.post(this);
            this.id = response?.data?.created_id || response?.data?.id;
        }

        if (!this.send_file_url) {
            throw new Error('Media ID not found when trying to create file PUT URL');
        }

        await request({
            method: 'PUT',
            url: this.send_file_url,
            data: { file },
            headers: attachHeaders({ 'Content-Type': 'multipart/form-data' })
        });

        this.files_awaiting_upload = null;
    }

    $download() {
        const url = this.url || this.external_url;
        download(url, this.download ? this.name : false);
    }

    get is_type_adviser() {
        return this.library_type === MediaTypeEnum.ADVISER;
    }

    get is_type_investor() {
        return this.library_type === MediaTypeEnum.INVESTOR;
    }

    get is_type_marketing() {
        return this.library_type === MediaTypeEnum.MARKETING;
    }

    get base_url() {
        return `${config.API_BASE_URL}/v1/media/${this.library_key}`;
    }

    get send_file_url() {
        if (!this.id) {
            return null;
        }
        return `${this.base_url}/${this.id}/file`;
    }

    get preview() {
        let preview = null;

        if (this.file_awaiting_upload && this.file_awaiting_upload instanceof File) {
            preview = toImage(this.file_awaiting_upload);

            if (!preview) {
                preview = {
                    name: this.file_awaiting_upload.name,
                    type: this.file_awaiting_upload.type,
                    size: this.file_awaiting_upload.size || null,
                    format: this.format,
                    src: null
                };
            } else {
                preview.format = 'IMAGE';
            }

            if (preview) {
                preview.uploaded = false;
                preview.alt = this.name;
            }
        } else if (this.file) {
            preview = {
                name: this.file?.filename,
                type: this.file?.mimetype,
                size: this.file?.size || null,
                format: this.format,
                src: this.external_url || this.url,
                uploaded: true,
                alt: this.alt_text,
                video: this.toVideo()
            };
        } else if (this.is_type_adviser && this.has_external_url) {
            preview = {
                name: this.alt_text,
                type: null,
                size: null,
                format: this.format,
                src: this.external_url,
                uploaded: true,
                alt: this.alt_text
            };
        }

        if (preview && preview.size) {
            preview.size = toNumber(preview.size, {
                notation: 'compact',
                style: 'unit',
                unit: 'byte',
                unitDisplay: 'narrow'
            });
        }

        if (preview) {
            preview.icon = getFileTypeIcon(preview.type);
        }

        return preview;
    }

    get library_key() {
        return kebabCase(this.library_type.toLowerCase());
    }

    get is_image() {
        return this.format === MediaFormatEnum.IMAGE;
    }

    get is_video() {
        return this.format === MediaFormatEnum.VIDEO;
    }

    get is_document() {
        return this.format === MediaFormatEnum.DOC;
    }

    get mimetype() {
        if (!this.file) {
            return null;
        }
        if (typeof this.file !== 'object') {
            return null;
        }

        if (!('mimetype' in this.file)) {
            return null;
        }

        return this.file.mimetype;
    }

    get icon() {
        let icon = getFileTypeIcon(this.mimetype);

        if (icon === 'file') {
            if (this.is_image) {
                return 'fileImage';
            } else if (this.is_video) {
                return 'fileVideo';
            } else if (this.is_document) {
                return 'fileDocument';
            }
        }

        return icon;
    }

    get has_preview() {
        return (this.is_image || this.is_video) && this.url;
    }

    get files_awaiting_upload() {
        return this.file_awaiting_upload ? [this.file_awaiting_upload] : [];
    }

    set files_awaiting_upload(files) {
        if (files && Array.isArray(files) && files.length) {
            this.file = null;
            this.file_awaiting_upload = files[0];
        } else {
            this.file_awaiting_upload = null;
        }
    }

    get has_internal_url() {
        return this.url && this.file?.mimetype;
    }

    get has_external_url() {
        return this.external_url;
    }

    get is_youtube_url() {
        return this.has_external_url && YouTubeUrl.validator(this.external_url) === true;
    }

    get is_valid_video() {
        return this.is_video && (this.has_internal_url || this.has_external_url);
    }

    get file_status() {
        if (this.file && typeof this.file === 'object' && 'status' in this.file) {
            return this.file.status;
        }

        return null;
    }

    get file_being_scanned() {
        return this.file_status === 'IN_PROGRESS';
    }

    get file_clean() {
        return ['CLEAN', 'NOT_SCANNED'].includes(this.file_status);
    }

    get file_infected() {
        return this.file_status === 'INFECTED';
    }

    get file_scan_error() {
        return this.file_status === 'ERROR';
    }

    get is_downloadable() {
        if (this.file_awaiting_upload) {
            return false;
        }

        if (this.has_external_url && !this.is_type_adviser) {
            return false;
        }

        if (this.file_being_scanned) {
            return false;
        }

        if (this.file_infected) {
            return false;
        }

        if (this.file_scan_error) {
            return false;
        }

        return true;
    }

    toVideo() {
        if (!this.is_valid_video) {
            return null;
        }

        let options = {
            id: this.id,
            title: this.name,
            description: this.description,
            autoplay: false,
            muted: false,
            language: 'en',
            sources: []
            // poster: video.poster
        };

        if (this.is_youtube_url) {
            options.techOrder = ['youtube'];
            options.sources.push({
                type: 'video/youtube',
                src: this.external_url
            });
        } else if (this.has_internal_url) {
            options.sources.push({
                type: this.file.mimetype,
                src: this.url
            });
        }

        return options;
    }
}

export default Media;
