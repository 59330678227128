import { Organisation, Product } from '@/models';
import ProviderApi from '@/api/ProviderApi';

export class Provider extends Organisation {
    static name = 'Provider';
    static entity = 'providers';
    static Api = ProviderApi;

    static fields() {
        return {
            ...super.fields(),
            products: this.hasMany(Product, 'company_id')
        };
    }
}

export default Provider;
