const EsignParticipantRole = Object.freeze({
    SIGNER: 'SIGNER',
    APPROVER: 'APPROVER',
    CERTIFIED_RECIPIENT: 'CERTIFIED_RECIPIENT',
    NOTARY_SIGNER: 'NOTARY_SIGNER',
    ELECTRONIC_SEALER: 'ELECTRONIC_SEALER',
    COPY_RECIPIENT: 'COPY_RECIPIENT'
});

export default EsignParticipantRole;
