import { BaseModel } from '@/models';
import IntegrationTypeEnum from '@/enums/integration/type';
import IntegrationStatusEnum from '@/enums/integration/status';

export class Integration extends BaseModel {
    static name = 'Integration';
    static entity = 'integrations';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(null).nullable(),
            type: this.enum(IntegrationTypeEnum).nullable(),
            status: this.enum(IntegrationStatusEnum).nullable()
        };
    }
}

export default Integration;
