import { mapGetters } from 'vuex';
import Collection from '@/models/Collection';

export default {
    components: {
        AppToggleNavTotals: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppToggleNavTotals'
            ),
        AppToggleConsolidatedView: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppToggleConsolidatedView'
            ),
        AppTogglePanelOffers: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppTogglePanelOffers'
            ),
        AppToggleClosedOffers: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppToggleClosedOffers'
            ),
        AppToggleQuarantinedMedia: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppToggleQuarantinedMedia'
            ),
        AppToggleClosedPositions: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppToggleClosedPositions'
            ),
        InvestorAdviserAccountSelector: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/InvestorAdviserAccountSelector'
            )
    },
    data: () => ({
        items: [],
        requestItems: null,
        Collection
    }),
    computed: {
        ...mapGetters('preferences', [
            'show_nav_totals',
            'show_consolidated_view',
            'show_approved_panel_only',
            'show_closed_offers',
            'show_quarantined_media',
            'show_closed_positions'
        ])
    },
    methods: {
        onCollectionRequestError(error, callback = null) {
            if (error?.code === 403) {
                this.$router.push({
                    params: {
                        investor_id: null
                    }
                });
            } else {
                callback = !callback ? () => {} : typeof callback === 'function' ? callback : this.setup();
                this.handleError(error, () => callback);
            }
        }
    }
};
