import isPlainObject from 'lodash/isPlainObject';
import has from 'lodash/has';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import FilterOperator from '@/enums/filter/operator';

const filterOperatorValues = map(FilterOperator, 'value');

export const defaultResult = {
    filters: {},
    original: null,
    operator: null,
    value: null
};

/**
 * Returns the filters with the extracted value removed and a copy of the extracted value
 *
 * @param {Object} filters
 * @param {String} search
 * @returns {Object}
 */
export function extractFilter(filters = {}, filterKey = '') {
    let result = { ...defaultResult };

    if (!isPlainObject(filters)) {
        return result;
    }

    result = {
        ...result,
        filters
    };

    if (has(filters, filterKey)) {
        let filter = filters[`${filterKey}`];

        if (Array.isArray(filter) && filter.length) {
            filter = filter[0];
        }

        let split = filter?.match(/([^:]+):(.*)/)?.slice(1) || [];

        if (split.length > 1) {
            if (filterOperatorValues.includes(split[0])) {
                result.operator = split[0];
                result.value = split[1];
                result.original = filters[`${filterKey}`];
                result.filters = omitBy(filters, (value, key) => key === filterKey);
            }
        } else {
            result.operator = FilterOperator.IS.value;
            result.value = filter;
            result.original = filters[`${filterKey}`];
            result.filters = omitBy(filters, (value, key) => key === filterKey);
        }

        return result;
    }

    return result;
}

export default extractFilter;
