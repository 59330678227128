import { BaseModel, Adviser, Investor } from '@/models';
import VisibilityGroupApi from '@/api/VisibilityGroupApi';

export class VisibilityGroup extends BaseModel {
    static name = 'VisibilityGroup';
    static entity = 'visibilityGroups';
    static Api = VisibilityGroupApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            name: this.string(null).nullable(),
            adviser_users: this.attr([]).nullable(),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            investor_ids: this.attr([]).nullable(),
            investors: this.hasManyBy(Investor, 'investor_ids')
        };
    }

    get investor_names() {
        if (this.investors && typeof this.investors === 'string') {
            return this.investors.split(',').map(name => name.trim());
        }

        if (!this.investors || !Array.isArray(this.investors)) {
            return [];
        }

        const names = this.investors.map(investor => investor.name);

        names.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

        return names;
    }

    async $get() {
        this.$loading = true;

        return await this.constructor.$get(this.adviser_id, this.id).finally(() => {
            this.$loading = false;
        });
    }
    static async $get(adviser_id, id) {
        await this.api.get(adviser_id, id);

        return this.findBy({ adviser_id, id });
    }

    $toPayload() {
        const pl = super.$toPayload();
        pl.investors = pl.investor_ids;
        delete pl.investor_ids;
        delete pl.adviser;
        delete pl.adviser_users;
        return pl;
    }
}

export default VisibilityGroup;
