import { BaseModel, Adviser, Investor, Product, Transaction, Tranche, Proposal } from '@/models';
import TaxStatusEnum from '@/enums/taxStatus';
import HoldingApi from '@/api/HoldingApi';
import HoldingStatusEnum from '@/enums/holding/status';
import HoldingAllotmentStatus from '@/enums/holding/allotmentStatus';
import parseValidationErrors from '@/lib/helpers/parseValidationErrors';
import getTaxRelief from '@/lib/helpers/getTaxRelief';
import config from '@/config';

export class Holding extends BaseModel {
    static name = 'Holding';
    static entity = 'holdings';
    static Api = HoldingApi;

    static fields() {
        return {
            ...super.fields(),
            $allotting: this.boolean(false).nullable(),
            $allotment_rejection_reason: this.attr(null).nullable(),

            id: this.attr(null),
            name: this.string(null).nullable(),
            status: this.enum(HoldingStatusEnum).nullable(),
            gi_ref: this.string(null).nullable(),
            purchased_on: this.string(null).nullable(),
            invested_amount: this.attr(this.defaultMoney).nullable(),
            gross_investment_amount: this.attr(this.defaultMoney).nullable(),
            share_class: this.string(null).nullable(),
            share_quantity: this.number(null).nullable(),
            share_issue_price: this.attr(this.defaultMoney).nullable(),
            share_issue_on: this.string(null).nullable(),
            tax_status: this.enum(TaxStatusEnum).nullable(),
            tax_year: this.string(null).nullable(),
            disposition_on: this.string(null).nullable(),
            disposition_result: this.string(null).nullable(),
            disposition_tax_year: this.string(null).nullable(),
            current_return_gross: this.attr(this.defaultMoney).nullable(),
            irr: this.number(null).nullable(),
            moic: this.number(null).nullable(),
            current_return_net: this.attr(this.defaultMoney).nullable(),
            initial_tax_relief: this.attr(this.defaultMoney).nullable(),
            tax_relief: this.attr(this.defaultMoney).nullable(),
            current_share_price: this.attr(this.defaultMoney).nullable(),
            failure_on: this.string(null).nullable(), // Remove?
            share_certificate_received_on: this.string(null).nullable(),
            eis_certificate_received_on: this.string(null).nullable(),
            application_on: this.string(null).nullable(),
            nav: this.attr(this.defaultMoney).nullable(),
            nav_discount: this.number(null).nullable(),
            nav_price: this.attr(this.defaultMoney).nullable(),
            revaluation_on: this.string(null).nullable(),
            cgt_relief: this.attr(this.defaultMoney).nullable(),
            share_count: this.number(null).nullable(),
            interest_dividend: this.attr(this.defaultMoney).nullable(),
            uninvested_capital: this.attr(this.defaultMoney).nullable(),
            cash_balance: this.attr(this.defaultMoney).nullable(),
            valuations: this.attr(null).nullable(),
            reconciled: this.boolean(false).nullable(),
            in_custody: this.boolean(false).nullable(),
            pending_allotment: this.boolean(false).nullable(),
            allotment: this.attr(null).nullable(),
            dri_enrolled: this.boolean(false).nullable(),
            dri_enrolled_at: this.string(null).nullable(),
            single_company: this.boolean(null).nullable(),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            product_id: this.string(null).nullable(),
            product: this.belongsTo(Product, 'product_id'),

            transaction_id: this.string(null).nullable(),
            transaction: this.belongsTo(Transaction, 'transaction_id'),

            tranche_id: this.string(null).nullable(),
            tranche: this.belongsTo(Tranche, 'tranche_id'),

            proposal_id: this.string(null).nullable(),
            proposal: this.belongsTo(Proposal, 'proposal_id')
        };
    }

    setupAllotment() {
        this.allotment = {
            share_quantity: this.share_quantity || null,
            share_issue_price: this.share_issue_price || null,
            share_issue_on: this.share_issue_on || null,
            initial_tax_relief: this.initial_tax_relief || null
        };

        if (!this.allotment.initial_tax_relief) {
            this.setAllotmentTaxRelief();
        }

        return this;
    }

    //  EIS and VCT = 0.3 x gross_investment_amount
    //  SEIS = 0.5 x gross_investment_amount
    getAllotmentTaxRelief() {
        const taxRelief = {
            currency: 'GBP',
            amount: 0
        };

        if (!this.product || !this.product.tax_status) {
            return taxRelief;
        }

        if (!this.gross_investment_amount || !this.gross_investment_amount.amount) {
            return taxRelief;
        }

        taxRelief.amount = getTaxRelief(this.product.tax_status, this.gross_investment_amount.amount);

        return taxRelief;
    }

    setAllotmentTaxRelief() {
        this.allotment.initial_tax_relief = this.getAllotmentTaxRelief();
    }

    get allotment_amount() {
        const amount = {
            currency: null,
            amount: null
        };

        if (this.allotment && this.allotment.share_issue_price?.amount && this.allotment.share_quantity) {
            amount.currency = this.allotment.share_issue_price.currency || 'GBP';
            amount.amount = Number(
                (this.allotment.share_issue_price.amount * this.allotment.share_quantity).toFixed(2)
            );
        }

        return amount;
    }

    get allotment_amount_exceeds_proposed_amount() {
        const tolerance = config.ALLOTMENT_AMOUNT_TOLERANCE || 0.01;
        return this.allotment_amount.amount - tolerance > (this.gross_investment_amount?.amount || 0);
    }

    get allotment_status() {
        if (!this.pending_allotment) {
            return HoldingAllotmentStatus.ALLOTTED;
        }

        if (this.$allotting) {
            return HoldingAllotmentStatus.IN_PROGRESS;
        }

        if (this.$allotment_rejection_reason) {
            return HoldingAllotmentStatus.REJECTED;
        }

        if (this.allotment_amount_exceeds_proposed_amount) {
            return HoldingAllotmentStatus.ALLOTMENT_EXCEEDS_PROPOSED_AMOUNT;
        }

        return HoldingAllotmentStatus.PENDING;
    }

    get is_pending() {
        return this.allotment_status === HoldingAllotmentStatus.PENDING;
    }

    get is_allotted() {
        return this.allotment_status === HoldingAllotmentStatus.ALLOTTED;
    }

    get is_rejected() {
        return this.allotment_status === HoldingAllotmentStatus.REJECTED;
    }

    get is_in_progress() {
        return this.allotment_status === HoldingAllotmentStatus.IN_PROGRESS;
    }

    get ready_for_allotment() {
        return (
            this.is_pending &&
            this.allotment &&
            this.allotment.share_quantity &&
            this.allotment.share_issue_price &&
            this.allotment.share_issue_on &&
            this.allotment.initial_tax_relief
        );
    }

    async $allot() {
        if (!this.id) {
            throw new ReferenceError('Missing ID required while attempting to allot holding');
        }

        this.$allotting = true;

        this.$saving = Object.keys(this.allotment);

        let holding = null;

        try {
            const response = await this.constructor.api.allot(this.id, this.allotment);

            if (
                response &&
                typeof response === 'object' &&
                response.status === 'success' &&
                typeof response.data === 'object' &&
                response.data &&
                'id' in response.data
            ) {
                holding = this.constructor.find(this.id);
            }

            if (!holding) {
                await this.constructor.api.get(this.id);
                holding = this.constructor.find(this.id);
            }

            holding.setupAllotment();
        } catch (error) {
            const errors = parseValidationErrors(error?.response?.data?.data)
                .filter(error => error.field !== '__root__')
                .map(error => error.name);

            const message = error.response?.data?.message || error.message;
            this.$saving = [];
            this.$allotment_rejection_reason = [message, ...errors];
            holding = this;
        }

        this.$allotting = false;

        return holding;
    }
}

export default Holding;
